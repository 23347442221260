.post-detail {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    /* background: #fff; */
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  p {
    line-height: 1.8;
  }
  
  img {
    max-width: 100%;
    border-radius: 8px;
    margin-top: 20px;
  }