/* 헤더 스타일 */
.main-header {
  background-color: #f8f9fa;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 헤더 그림자 */
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* 로고 섹션 */
.logo-section {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.logo-image {
  width: 40px;
  height: 40px;
}

.clickable-title {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  transition: color 0.3s ease;
}

.clickable-title:hover {
  color: #007bff;
  text-decoration: underline;
}

/* 네비게이션 링크 */
.nav-links {
  display: flex;
  gap: 15px;
}

.nav-button {
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 5px;
  background-color: #007bff;
  border: 1px solid #007bff;
  transition: background-color 0.3s ease;
}

.nav-button:hover {
  background-color: #0056b3;
}

/* 프로필 섹션 */
.profile-section {
  position: relative;
}

.profile-button {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-button:hover {
  background-color: #0056b3;
}

.profile-modal {
  position: absolute;
  top: 50px;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 100;
}

.profile-modal p {
  margin: 5px 0;
  font-size: 0.9rem;
}

.logout-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  width: 100%;
}

.logout-button:hover {
  background-color: #a71d2a;
}
