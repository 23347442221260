.main-page {
  position: relative; /* 버튼 절대 위치 설정을 위한 상대 위치 */
  max-width: 800px;
  margin: 80px auto; /* 가로 가운데 정렬 */
  display: flex;
  flex-direction: column;
  align-items: center; /* 수평 가운데 정렬 */
  padding: 20px; /* 내부 여백 추가 */
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 뒤로가기 버튼 스타일 */
.back-button {
  position: absolute;
  top: 20px;       /* 상단에서 20px 아래 */
  left: 20px;      /* 왼쪽에서 20px 오른쪽 */
  background: none;  /* 배경 투명 */
  border: none;      /* 테두리 제거 */
  font-size: 20px;   /* 글자 크기 */
  cursor: pointer;   /* 마우스 포인터로 변경 */
  color: #000000;    /* 글자 색상 */
}


.back-button:hover {
  background-color: transparent !important; /* 호버 시 파란색 배경 X */
  color: #007bff !important;                /* 글자색 변화 X */
}
  

/* 이하 기존 스타일 그대로 */
.section-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center; /* 수평 가운데 정렬 */
}

.section-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s, margin 0.3s; /* 슬라이드 애니메이션 추가 */
  cursor: pointer;
}

.section-card.slide-out {
  margin-left: -120%;
  transform: translateX(-50%);
  opacity: 0;
}

.section-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

h3 {
  margin: 10px 0;
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
  color: #666;
}

h2 {
  margin: 10px 0;
  font-size: 1.5rem;
}

.post-section {
  margin-top: 20px;
}

.post-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.post-card {
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.post-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.post-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.post-card p {
  margin: 5px 0;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.category-selection-section {
  text-align: center;
  margin-top: 20px;
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.category-button {
  padding: 10px 20px;
  font-size: 18px;
  border: 2px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f5f5f5;
  transition: all 0.3s ease;
}

.category-button:hover {
  background-color: #ddd;
  border-color: #999;
}
