.form-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-container input[type="text"],
  .form-container textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .form-container input[type="file"] {
    margin: 10px 0;
  }
  
  .form-container button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
  }

  .react-quill-container {
    height: 500px; /* 콘텐츠 창 높이 */
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow-y: auto; /* 스크롤 추가 */
  }
  
  .react-quill-container .ql-toolbar {
    height: 50px; /* 도구 막대 높이 */
  }
  .react-quill-container .ql-container {
    height: 450px; /* 에디터 높이 */
  }
  