.main-page {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .post-section {
    text-align: center;
  }
  
  .post-list ul {
    list-style: none;
    padding: 0;
  }
  
  .post-list li {
    margin: 10px 0;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .post-list li:hover {
    transform: scale(1.02);
  }